import PriceQuote from '../'
import PriceQuotePopup from '../popup/price-quote'
import importedPriceQuotes from '../popup/imported-price-quotes.vue'

export default [
  {
    path: '/price-quote',
    name: 'PriceQuote',
    component: PriceQuote,
    meta: {
      main_menu: true,
      slug: 'price-quote',
      category: 'Buy',
      permissionKey: 'PriceQuotaMenu',
      page: 'priceQuote'
    },
    children: [
      {
        path: 'new',
        name: 'NewPriceQuote',
        component: PriceQuotePopup
      },
      {
        path: ':id/edit',
        name: 'EditPriceQuote',
        component: PriceQuotePopup,
        props: {
          status: 'edit'
        }
      },
      {
        path: 'imported-price-quotes',
        name: 'importedPriceQuotes',
        component: importedPriceQuotes
      }
    ]
  }
]
