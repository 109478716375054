var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("PriceQuota.popupForm_Title_Edit")))]
            : [_vm._v(_vm._s(_vm.$t("PriceQuota.popupForm_Title_Create")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "div",
          { ref: "popupContent", staticClass: "price-quote-form-container" },
          [
            _vm.isLoading
              ? _c("Loading")
              : _c(
                  "form",
                  {
                    staticClass: "Form",
                    attrs: {
                      id: "form-price-quote",
                      "data-vv-scope": "formPriceQuote",
                    },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmitForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("PriceQuota.popupForm_Field_SupplierName")
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "supplierRef",
                            attrs: {
                              componentName: "price-quote-form-supplier",
                              name: "supplier",
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_SupplierName"
                              ),
                              disabled: _vm.isEdit,
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("supplier"),
                              },
                              "on-search": _vm.getSupplierSelectOptions,
                              options: _vm.supplierSelectOptions,
                              label: "name",
                              placeholder: _vm.$t(
                                "PriceQuota.popupForm_Field_SupplierName_Placeholder"
                              ),
                            },
                            on: { input: _vm.onChangeSupplier },
                            model: {
                              value: _vm.form.supplier,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "supplier", $$v)
                              },
                              expression: "form.supplier",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("supplier"),
                                expression: "veeErrors.has('supplier')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("supplier"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Field_StockItem"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "stockItem",
                            attrs: {
                              componentName: "price-quote-form-stock-item",
                              name: "stockItem",
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_StockItem"
                              ),
                              disabled: _vm.isEdit,
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("stockItem"),
                              },
                              "on-search": _vm.getStockItemSelectOptions,
                              options: _vm.stockItemSelectOptions,
                              label: "name",
                              placeholder: _vm.$t(
                                "PriceQuota.popupForm_Field_StockItem_Placeholder"
                              ),
                              directSelectOption: _vm.directSelectOption,
                            },
                            on: { input: _vm.changedStockItem },
                            model: {
                              value: _vm.form.stockItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stockItem", $$v)
                              },
                              expression: "form.stockItem",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("stockItem"),
                                expression: "veeErrors.has('stockItem')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("stockItem"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Field_Unit"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("svg", { staticClass: "icon icon-down-arrow" }, [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-down-arrow" },
                            }),
                          ]),
                          _c("customSelectInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            attrs: {
                              name: "unitId",
                              optionData: _vm.relativeUnitList,
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_Unit"
                              ),
                              error: _vm.veeErrors.has("formPriceQuote.unitId"),
                              disabled: _vm.isEdit,
                              id: "select-price-quote-unit",
                              optionIdName: "option-price-quote-unit",
                            },
                            model: {
                              value: _vm.form.unitId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "unitId", $$v)
                              },
                              expression: "form.unitId",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "formPriceQuote.unitId"
                                ),
                                expression:
                                  "veeErrors.has('formPriceQuote.unitId')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first(
                                "formPriceQuote.unitId"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Field_barcode"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control barcode-input" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.barcode,
                                expression: "barcode",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: `numeric|verify_barcode|verify_weighted_barcode:${_vm.form.unitId}`,
                                expression:
                                  "`numeric|verify_barcode|verify_weighted_barcode:${form.unitId}`",
                              },
                            ],
                            staticClass: "txt",
                            class: {
                              "is-danger": _vm.veeErrors.has(
                                `formPriceQuote.barcode`
                              ),
                            },
                            attrs: {
                              autocomplete: "off",
                              name: "barcode",
                              placeholder: _vm.$t(
                                "PriceQuota.popupForm_Field_barcode_Placeholder"
                              ),
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_barcode"
                              ),
                              type: "text",
                              disabled: _vm.disableBarcodeInput,
                              "data-vv-delay":
                                _vm.$getConst("INPUT_DELAY_TIME"),
                              id: "input-price-quote-popup-field-barcode-stock-item-code",
                            },
                            domProps: { value: _vm.barcode },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.addBarcode.apply(null, arguments)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.barcode = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "barcode-actions" },
                            [
                              _vm.isLoadingCheckBarcode
                                ? _c("Loading", { attrs: { theme: "disable" } })
                                : _c(
                                    "Button",
                                    {
                                      attrs: {
                                        size: "small",
                                        clearVariant: true,
                                        disabled:
                                          !_vm.barcode ||
                                          _vm.disableBarcodeInput ||
                                          _vm.veeErrors.has(
                                            `formPriceQuote.barcode`
                                          ),
                                      },
                                      on: { click: _vm.addBarcode },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "icon",
                                          class: [
                                            _vm.barcode &&
                                            !_vm.disableBarcodeInput &&
                                            !_vm.veeErrors.has(
                                              `formPriceQuote.barcode`
                                            )
                                              ? "c-success"
                                              : "c-gray",
                                          ],
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#icon-global-add",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "formPriceQuote.barcode"
                                ),
                                expression:
                                  "veeErrors.has('formPriceQuote.barcode')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first(
                                "formPriceQuote.barcode"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "barcodes" },
                        _vm._l(_vm.form.barcodes, function (barcode, index) {
                          return _c("div", [
                            _c("span", { staticClass: "barcodes-text" }, [
                              _vm._v(_vm._s(barcode)),
                              _c(
                                "button",
                                {
                                  staticClass: "barcodes-text-remove",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeBarcode(index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    { staticClass: "icon icon-barcode-close" },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#icon-barcode-close",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _vm.form.barcodeOffers && _vm.form.barcodeOffers.length
                      ? _c("div", { staticClass: "Form-item relative" }, [
                          _c(
                            "div",
                            { staticClass: "Form-item-label linear-container" },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "PriceQuota.popupForm_Field_Barcodes_Offers"
                                    )
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "linear" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "barcodes",
                              attrs: { id: "price-quote-barcode-offers" },
                            },
                            _vm._l(
                              _vm.form.barcodeOffers,
                              function (barcode, index) {
                                return _c("div", [
                                  _vm.form.barcodes.indexOf(barcode) === -1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "barcodes-offer-text c-light",
                                        },
                                        [
                                          _vm._v(_vm._s(barcode)),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "barcodes-offer-text-add",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addBarcodeOffer(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "icon icon-global-add",
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      "xlink:href":
                                                        "#icon-global-add",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm.isEdit
                      ? _c("div", { staticClass: "Form-item m-top-20" }, [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Stocks.popup_createBarcode"))
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control create-barcode" },
                            [
                              _c("CustomCheckbox", {
                                attrs: {
                                  id: "input-price-quote-popup-field-barcode",
                                  label: _vm.$t(
                                    "Stocks.popupForm_GenerateWeightedBarcode"
                                  ),
                                  disabled:
                                    _vm.disableBarcodeInput ||
                                    !_vm.isWeightedUnit(this.form.unitId),
                                },
                                on: { change: _vm.changeBarcodeType },
                                model: {
                                  value: _vm.form.isWeightedBarcode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isWeightedBarcode", $$v)
                                  },
                                  expression: "form.isWeightedBarcode",
                                },
                              }),
                              _c(
                                "Button",
                                {
                                  attrs: {
                                    size: "small",
                                    primary: "",
                                    disabled:
                                      _vm.disableBarcodeInput ||
                                      _vm.disableBarcodeButton,
                                  },
                                  on: { click: _vm.createBarcode },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Stocks.popup_createBarcode")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "PriceQuota.popupForm_Field_SupplierStockItemCode"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customTextInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.stockItemCodeValRule,
                                expression: "stockItemCodeValRule",
                              },
                            ],
                            attrs: {
                              name: "supplierStockItemCode",
                              id: "input-price-quote-popup-field-supplier-stock-item-code",
                              placeholder: _vm.$t(
                                "PriceQuota.popupForm_Field_SupplierStockItemCode_Placeholder"
                              ),
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_SupplierStockItemCode"
                              ),
                              error: _vm.veeErrors.has(
                                "formPriceQuote.supplierStockItemCode"
                              ),
                              disabled:
                                !_vm.form.supplier || !_vm.form.stockItem,
                              isDelayInput: true,
                            },
                            model: {
                              value: _vm.form.supplierStockItemCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "supplierStockItemCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.supplierStockItemCode",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "formPriceQuote.supplierStockItemCode"
                                ),
                                expression:
                                  "veeErrors.has('formPriceQuote.supplierStockItemCode')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first(
                                "formPriceQuote.supplierStockItemCode"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popup_FormField_priceType"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control control form-item-select" },
                        [
                          _c("svg", { staticClass: "icon icon-down-arrow" }, [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-down-arrow" },
                            }),
                          ]),
                          _c("customSelectInput", {
                            attrs: {
                              name: "priceType",
                              optionData: _vm.priceTypes,
                              optionKey: "value",
                              optionIdKey: "value",
                              isValueNumber: true,
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popup_FormField_priceType"
                              ),
                              error: _vm.veeErrors.has("priceType"),
                              hideDefault: true,
                              disabled: !_vm.checkPermission("PriceTypeEdit"),
                              id: "select-inventory-price-type",
                            },
                            model: {
                              value: _vm.form.priceType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "priceType", $$v)
                              },
                              expression: "form.priceType",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("priceType"),
                                expression: "veeErrors.has('priceType')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("priceType"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Field_UnitPrice"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("currency-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|greater_than:0",
                                expression: "'required|greater_than:0'",
                              },
                            ],
                            staticClass: "txt currency-input",
                            class: {
                              "is-danger": _vm.veeErrors.has(
                                "formPriceQuote.unitPrice"
                              ),
                            },
                            attrs: {
                              name: "unitPrice",
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_UnitPrice"
                              ),
                              disabled: !_vm.form.supplier,
                              id: "input-price-quote-unit-price",
                            },
                            model: {
                              value: _vm.form.unitPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "unitPrice", $$v)
                              },
                              expression: "form.unitPrice",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "currency-symbol",
                              class: !_vm.form.supplier
                                ? "currency-symbol-faded"
                                : "",
                            },
                            [_vm._v(_vm._s(_vm.currencySymbol))]
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "formPriceQuote.unitPrice"
                                ),
                                expression:
                                  "veeErrors.has('formPriceQuote.unitPrice')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first(
                                "formPriceQuote.unitPrice"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("PriceQuota.popupForm_Field_Discount1")
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "greater_than:-1|max_value:100",
                                  expression: "'greater_than:-1|max_value:100'",
                                },
                              ],
                              attrs: {
                                name: "discount1",
                                id: "input-price-quote-popup-field-discount1",
                                placeholder: ",00",
                                "data-vv-as": _vm.$t(
                                  "PriceQuota.popupForm_Field_Discount1"
                                ),
                                error: _vm.veeErrors.has("discount1"),
                                isTypePercentage: true,
                              },
                              model: {
                                value: _vm.form.discount1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "discount1", $$v)
                                },
                                expression: "form.discount1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.discountControl,
                                expression: "!discountControl",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Despatches.discountControl_Message")
                              )
                            ),
                          ]
                        ),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("discount1"),
                              expression: "veeErrors.has('discount1')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("discount1"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Field_Discount2"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customNumberInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "greater_than:-1|max_value:100",
                                expression: "'greater_than:-1|max_value:100'",
                              },
                            ],
                            attrs: {
                              name: "discount2",
                              id: "input-price-quote-popup-field-discount2",
                              placeholder: ",00",
                              "data-vv-as": _vm.$t(
                                "PriceQuota.popupForm_Field_Discount2"
                              ),
                              error: _vm.veeErrors.has("discount2"),
                              isTypePercentage: true,
                              disabled:
                                !_vm.form.discount1 ||
                                _vm.form.discount1 === "0",
                            },
                            model: {
                              value: _vm.form.discount2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "discount2", $$v)
                              },
                              expression: "form.discount2",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("discount2"),
                                expression: "veeErrors.has('discount2')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("discount2"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "Form-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Global.leadTime"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customNumberInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "greater_than:-1|max_value:99999999",
                                expression:
                                  "'greater_than:-1|max_value:99999999'",
                              },
                            ],
                            attrs: {
                              name: "leadTime",
                              id: "input-price-quote-popup-field-leadTime",
                              placeholder: _vm.$t(
                                "PriceQuota.popupForm_Field_LeadTime_Placeholder"
                              ),
                              "data-vv-as": _vm.$t("Global.leadTime"),
                              error: _vm.veeErrors.has("leadTime"),
                            },
                            model: {
                              value: _vm.form.leadTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "leadTime", $$v)
                              },
                              expression: "form.leadTime",
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("leadTime"),
                                expression: "veeErrors.has('leadTime')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first("leadTime"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("CustomCheckbox", {
                          attrs: {
                            id: "checkbox-price-quote-Date",
                            label: _vm.$t(
                              "PriceQuota.popupForm_Field_ValidityDateToggleLabel"
                            ),
                          },
                          model: {
                            value: _vm.form.isValidityDateActive,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isValidityDateActive", $$v)
                            },
                            expression: "form.isValidityDateActive",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.isValidityDateActive
                      ? _c(
                          "div",
                          { staticClass: "Form-item inline-container" },
                          [
                            _c("div", { staticClass: "Form-item" }, [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "PriceQuota.popupForm_Field_StartDate"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-item-select start-date" },
                                [
                                  _c("CustomDatepicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    class: {
                                      "is-danger":
                                        _vm.veeErrors.has("startDate"),
                                    },
                                    attrs: {
                                      id: "payment-form-start-date",
                                      name: "startDate",
                                      "data-vv-as": _vm.$t(
                                        "Payments.formPaymentDate"
                                      ),
                                      disabledStartDate: _vm.startDateDisabled,
                                      pickerType: "manuel",
                                      inputIconRight: "icon-datepicker-big",
                                      position: "top-left",
                                      inputIcon: "",
                                    },
                                    model: {
                                      value: _vm.form.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "startDate", $$v)
                                      },
                                      expression: "form.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "Form-item m-left-20" }, [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("PriceQuota.popupForm_Field_EndDate")
                                  )
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-item-select end-date" },
                                [
                                  _c("CustomDatepicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    class: {
                                      "is-danger":
                                        _vm.veeErrors.has("startDate"),
                                    },
                                    attrs: {
                                      id: "payment-form-end-date",
                                      name: "endDate",
                                      disabledStartDate: _vm.endDateDisabled,
                                      pickerType: "manuel",
                                      inputIconRight: "icon-datepicker-big",
                                      position: "top",
                                      inputIcon: "",
                                    },
                                    model: {
                                      value: _vm.form.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endDate", $$v)
                                      },
                                      expression: "form.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "Form-item" },
                          [
                            _c("CustomCheckbox", {
                              attrs: {
                                id: "checkbox-price-quote-save-and-new",
                                label: _vm.$t("Global.formSaveAndNewText", {
                                  form: _vm.$t("PriceQuota.popupForm_Name"),
                                }),
                              },
                              model: {
                                value: _vm.saveAndNew,
                                callback: function ($$v) {
                                  _vm.saveAndNew = $$v
                                },
                                expression: "saveAndNew",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.isEdit
            ? [
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      size: "large",
                      variant: "full",
                      id: "btn-price-quote-popup-submit-edit",
                      type: "submit",
                      form: "form-price-quote",
                      disabled: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Button_Edit"))
                        ),
                      ]
                    ),
                    _c("Loading", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { theme: "disable" },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      size: "large",
                      variant: "full",
                      id: "btn-price-quote-popup-submit-new",
                      type: "submit",
                      form: "form-price-quote",
                      disabled: _vm.isLoading || _vm.disabledButton,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("PriceQuota.popupForm_Button_Create"))
                        ),
                      ]
                    ),
                    _c("Loading", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { theme: "disable" },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }