<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('PriceQuota.popupForm_Title_Edit') }}
      template(v-else) {{ $t('PriceQuota.popupForm_Title_Create') }}

    template(slot="content")

      .price-quote-form-container(ref="popupContent")

        Loading(v-if="isLoading")

        form.Form(
        v-else
        id="form-price-quote"
        @submit.prevent="onSubmitForm"
        data-vv-scope="formPriceQuote")

          div.Form-item.required
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_SupplierName') }}
            .control
              custom-search(
                componentName="price-quote-form-supplier"
                name="supplier"
                ref="supplierRef"
                v-model="form.supplier"
                :data-vv-as="$t('PriceQuota.popupForm_Field_SupplierName')"
                v-validate="'required'"
                :disabled="isEdit"
                :inputClass="{ 'is-danger': veeErrors.has('supplier') }"
                :on-search="getSupplierSelectOptions"
                :options="supplierSelectOptions"
                label="name"
                :placeholder="$t('PriceQuota.popupForm_Field_SupplierName_Placeholder')"
                @input="onChangeSupplier"
              )

              showValidateError(
                v-show="veeErrors.has('supplier')"
                :errorName="veeErrors.first('supplier')"
              )

          div.Form-item.required
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_StockItem') }}
            .control
              custom-search(
                componentName="price-quote-form-stock-item"
                name="stockItem"
                ref="stockItem"
                v-model="form.stockItem"
                :data-vv-as="$t('PriceQuota.popupForm_Field_StockItem')"
                v-validate="'required'"
                :disabled="isEdit"
                :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
                :on-search="getStockItemSelectOptions"
                :options="stockItemSelectOptions"
                label="name"
                :placeholder="$t('PriceQuota.popupForm_Field_StockItem_Placeholder')"
                :directSelectOption="directSelectOption"
                @input="changedStockItem"
              )

              showValidateError(
                v-show="veeErrors.has('stockItem')"
                :errorName="veeErrors.first('stockItem')"
              )

          div.Form-item.required
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_Unit') }}
            .control.form-item-select

              svg.icon.icon-down-arrow
                use(xlink:href="#icon-down-arrow")

              customSelectInput(
                name="unitId"
                :optionData="relativeUnitList"
                v-model="form.unitId"
                v-validate="'required|is_not:-1'"
                :data-vv-as="$t('PriceQuota.popupForm_Field_Unit')"
                :error="veeErrors.has('formPriceQuote.unitId')"
                :disabled="isEdit"
                id="select-price-quote-unit"
                optionIdName="option-price-quote-unit"
              )

              showValidateError(
                v-show="veeErrors.has('formPriceQuote.unitId')"
                :errorName="veeErrors.first('formPriceQuote.unitId')"
              )

          div.Form-item
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_barcode') }}
            .control.barcode-input
              input.txt(
              autocomplete="off"
              name="barcode"
              @keydown.prevent.enter="addBarcode"
              :placeholder="$t('PriceQuota.popupForm_Field_barcode_Placeholder')"
              :data-vv-as="$t('PriceQuota.popupForm_Field_barcode')"
              v-model="barcode"
              v-validate="`numeric|verify_barcode|verify_weighted_barcode:${form.unitId}`"
              :class="{ 'is-danger': veeErrors.has(`formPriceQuote.barcode`) }"
              type="text"
              :disabled="disableBarcodeInput"
              :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
              id="input-price-quote-popup-field-barcode-stock-item-code")

              .barcode-actions

                Loading(theme="disable" v-if="isLoadingCheckBarcode")

                Button(
                  v-else
                  size="small"
                  :clearVariant="true"
                  @click="addBarcode"
                  :disabled="!barcode || disableBarcodeInput || veeErrors.has(`formPriceQuote.barcode`)"
                )
                  svg.icon(:class="[barcode && !disableBarcodeInput && !veeErrors.has(`formPriceQuote.barcode`) ? 'c-success': 'c-gray']")
                    use(xlink:href="#icon-global-add")

              showValidateError(
                v-show="veeErrors.has('formPriceQuote.barcode')"
                :errorName="veeErrors.first('formPriceQuote.barcode')"
              )

            .barcodes
              div(v-for="(barcode, index) in form.barcodes")
                span.barcodes-text {{ barcode }}
                  button.barcodes-text-remove(
                    type="button"
                    @click="removeBarcode(index)"
                  )
                    svg.icon.icon-barcode-close
                      use(xlink:href="#icon-barcode-close")

          div.Form-item.relative(v-if="form.barcodeOffers && form.barcodeOffers.length")
            div.Form-item-label.linear-container
              label {{ $t('PriceQuota.popupForm_Field_Barcodes_Offers') }}
              .linear
            .barcodes(id="price-quote-barcode-offers")
              div(v-for="(barcode, index) in form.barcodeOffers")
                span.barcodes-offer-text.c-light(v-if="form.barcodes.indexOf(barcode) === -1") {{ barcode }}
                  button.barcodes-offer-text-add(
                    type="button"
                    @click="addBarcodeOffer(index)"
                  )
                    svg.icon.icon-global-add
                      use(xlink:href="#icon-global-add")
          
          .Form-item.m-top-20(
            v-if="isEdit"
          )
            label.Form-item-label {{ $t('Stocks.popup_createBarcode') }}
            .control.create-barcode
              CustomCheckbox(
                id="input-price-quote-popup-field-barcode"
                :label="$t('Stocks.popupForm_GenerateWeightedBarcode')"
                :disabled="disableBarcodeInput || !isWeightedUnit(this.form.unitId)"
                v-model="form.isWeightedBarcode"
                @change="changeBarcodeType"
              )
              Button(
                size="small"
                primary,
                @click="createBarcode"
                :disabled="disableBarcodeInput || disableBarcodeButton"
              )
                span {{ $t('Stocks.popup_createBarcode') }}

          div.Form-item
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_SupplierStockItemCode') }}
            .control
              customTextInput(
                name="supplierStockItemCode"
                id="input-price-quote-popup-field-supplier-stock-item-code"
                :placeholder="$t('PriceQuota.popupForm_Field_SupplierStockItemCode_Placeholder')"
                :data-vv-as="$t('PriceQuota.popupForm_Field_SupplierStockItemCode')"
                v-validate="stockItemCodeValRule"
                :error="veeErrors.has('formPriceQuote.supplierStockItemCode')"
                :disabled="!form.supplier || !form.stockItem"
                v-model.trim="form.supplierStockItemCode"
                :isDelayInput="true"
              )

              showValidateError(
                v-show="veeErrors.has('formPriceQuote.supplierStockItemCode')"
                :errorName="veeErrors.first('formPriceQuote.supplierStockItemCode')"
              )

          div.Form-item
            label.Form-item-label {{ $t('PriceQuota.popup_FormField_priceType') }}
            .control.control.form-item-select
              svg.icon.icon-down-arrow
                use(xlink:href="#icon-down-arrow")
              customSelectInput(
                name="priceType",
                :optionData="priceTypes"
                optionKey="value"
                optionIdKey="value"
                :isValueNumber="true"
                v-model="form.priceType"
                :data-vv-as="$t('PriceQuota.popup_FormField_priceType')"
                :error="veeErrors.has('priceType')"
                :hideDefault="true"
                :disabled="!checkPermission('PriceTypeEdit')"
                id="select-inventory-price-type"
              )

              showValidateError(
                v-show="veeErrors.has('priceType')"
                :errorName="veeErrors.first('priceType')"
              )

          div.Form-item.required
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_UnitPrice') }}
            .control
              currency-input.txt.currency-input(
              name="unitPrice",
              :data-vv-as="$t('PriceQuota.popupForm_Field_UnitPrice')",
              v-model="form.unitPrice",
              :disabled="!form.supplier"
              :class="{ 'is-danger': veeErrors.has('formPriceQuote.unitPrice') }",
              v-validate="'required|greater_than:0'"
              id="input-price-quote-unit-price")

              .currency-symbol(:class="!form.supplier ? 'currency-symbol-faded' : ''") {{ currencySymbol }}

              showValidateError(
                v-show="veeErrors.has('formPriceQuote.unitPrice')"
                :errorName="veeErrors.first('formPriceQuote.unitPrice')"
              )

          div.Form-item
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_Discount1') }}
            .control
              customNumberInput(
                name="discount1"
                id="input-price-quote-popup-field-discount1"
                placeholder=",00"
                :data-vv-as="$t('PriceQuota.popupForm_Field_Discount1')"
                :error="veeErrors.has('discount1')"
                v-validate="'greater_than:-1|max_value:100'"
                :isTypePercentage="true"
                v-model="form.discount1"
              )
            
            p.Form-item-help.is-danger(
              v-show="!discountControl"
            ) {{ $t('Despatches.discountControl_Message') }}

            showValidateError(
              v-show="veeErrors.has('discount1')"
              :errorName="veeErrors.first('discount1')"
            )

          div.Form-item
            label.Form-item-label {{ $t('PriceQuota.popupForm_Field_Discount2') }}
            .control
              customNumberInput(
                name="discount2"
                id="input-price-quote-popup-field-discount2"
                placeholder=",00"
                :data-vv-as="$t('PriceQuota.popupForm_Field_Discount2')"
                :error="veeErrors.has('discount2')"
                :isTypePercentage="true"
                v-validate="'greater_than:-1|max_value:100'"
                v-model="form.discount2"
                :disabled="!form.discount1 || form.discount1 === '0'"
              )

              showValidateError(
                v-show="veeErrors.has('discount2')"
                :errorName="veeErrors.first('discount2')"
              )
          div.Form-item
            label.Form-item-label {{ $t('Global.leadTime') }}
            .control
              customNumberInput(
                name="leadTime"
                id="input-price-quote-popup-field-leadTime"
                :placeholder="$t('PriceQuota.popupForm_Field_LeadTime_Placeholder')"
                :data-vv-as="$t('Global.leadTime')"
                :error="veeErrors.has('leadTime')"
                v-validate="'greater_than:-1|max_value:99999999'"
                v-model="form.leadTime"
              )
              showValidateError(
                v-show="veeErrors.has('leadTime')"
                :errorName="veeErrors.first('leadTime')"
              )

          div.Form-item
            CustomCheckbox(
            id="checkbox-price-quote-Date"
            v-model="form.isValidityDateActive"
            :label="$t('PriceQuota.popupForm_Field_ValidityDateToggleLabel')")

          div.Form-item.inline-container(v-if="form.isValidityDateActive")
            div.Form-item
              label.Form-item-label
                | {{ $t('PriceQuota.popupForm_Field_StartDate') }}
              .form-item-select.start-date
                CustomDatepicker(
                  id="payment-form-start-date"
                  name="startDate",
                  :data-vv-as="$t('Payments.formPaymentDate')",
                  v-model="form.startDate",
                  :class="{ 'is-danger': veeErrors.has('startDate') }",
                  :disabledStartDate="startDateDisabled",
                  v-validate="'required'"
                  pickerType="manuel",
                  inputIconRight="icon-datepicker-big"
                  position="top-left"
                  inputIcon="")

            div.Form-item.m-left-20
              label.Form-item-label
                | {{ $t('PriceQuota.popupForm_Field_EndDate') }}
              .form-item-select.end-date
                CustomDatepicker(
                id="payment-form-end-date"
                name="endDate",
                v-model="form.endDate",
                :class="{ 'is-danger': veeErrors.has('startDate') }",
                :disabledStartDate="endDateDisabled",
                v-validate="'required'"
                pickerType="manuel",
                inputIconRight="icon-datepicker-big"
                position="top"
                inputIcon="")

          div.Form-item(v-if="!isEdit")
            CustomCheckbox(
              id="checkbox-price-quote-save-and-new"
              v-model="saveAndNew"
              :label="$t('Global.formSaveAndNewText', { form: $t('PriceQuota.popupForm_Name') })")

    template(slot="footer")
      template(v-if="isEdit")
        Button(
        primary
        size="large"
        variant="full"
        id="btn-price-quote-popup-submit-edit"
        type="submit"
        form="form-price-quote"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('PriceQuota.popupForm_Button_Edit') }}
          Loading(theme="disable" v-show="isLoading")
      template(v-else)
        Button(
        primary
        size="large"
        variant="full"
        id="btn-price-quote-popup-submit-new"
        type="submit"
        form="form-price-quote"
        :disabled="isLoading || disabledButton")
          span(v-show="!isLoading") {{ $t('PriceQuota.popupForm_Button_Create') }}
          Loading(theme="disable" v-show="isLoading")

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { Validator } from 'vee-validate'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'
import { omit, getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'PriceQuoteForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  mixins: [getQuantityFromBarcode],

  data () {
    return {
      form: {
        supplier: null,
        stockItem: null,
        supplierStockItemCode: null,
        unitId: '-1',
        unitPrice: null,
        discount1: null,
        discount2: null,
        isValidityDateActive: false,
        startDate: null,
        endDate: null,
        barcodes: [],
        barcodeOffers: [],
        leadTime: null,
        priceType: 1,
        isWeightedBarcode: false
      },
      barcode: '',
      supplierSelectOptions: [],
      stockItemSelectOptions: [],
      availableStartDate: null,
      saveAndNew: true,
      discountControl: true,
      focusedBarcode: false,
      priceQuoteId: null,
      currencySymbol: null,
      validBarcode: {
        stockItemName: 'SSSS',
        unitName: 'UUUU'
      },
      disableBarcodeButton: false,
      priceTypes: [
        {
          value: 1,
          name: this.$t('PriceQuota.PriceType_Dynamic')
        },
        {
          value: 2,
          name: this.$t('PriceQuota.PriceType_Fixed')

        },
        {
          value: 3,
          name: this.$t('PriceQuota.PriceType_FixedOffer')
        }
      ]
    }
  },

  async created () {
    let self = this
    Validator.extend('verify_barcode', {
      // Custom validation message
      getMessage: () => self.$t('PriceQuota.checkBarcode', {stockItemName: self.validBarcode.stockItemName, unitName: self.validBarcode.unitName}),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let params = {
          Barcode: value,
          StockItemId: self.form.stockItem.id,
          UnitId: self.form.unitId
        }
        self.checkBarcode(params)
          .then(res => {
            if (res) {
              self.validBarcode.stockItemName = res.data.stockItemName
              self.validBarcode.unitName = res.data.unitName
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
      })
    })

    this.getRelativeUnitList({ baseUnitId: null }) // Reset Unitlist
    if (this.isEdit) {
      const priceQuote = (await this.getPriceQuote(this.$route.params.id)).data.priceQuote
      this.priceQuoteId = priceQuote.id
      this.currencySymbol = priceQuote.currencyDefinitionSymbol
      const stockItem = (await this.getStockItem(priceQuote.stockItemId)).data.stockItem
      const supplier = (await this.getSupplier(priceQuote.supplierId)).data.supplier
      this.form = {
        ...priceQuote,
        stockItem,
        supplier
      }

      this.availableStartDate = (await this.getAvailableStartDate({
        SupplierId: supplier.id,
        StockItemId: stockItem.id,
        UnitId: priceQuote.unitId
      })).data.startDate
    }
  },
  mounted () {
    this.formFocus()
    if (!this.isEdit) this.currencySymbol = this.activeCurrency.symbol
  },
  watch: {
    'form.isValidityDateActive' () {
      if (this.form.isValidityDateActive) {
        this.$nextTick(() => {
          this.$refs.popupContent.scrollTop = 9999
          if (!this.form.startDate) {
            this.form.startDate = moment()._d
          }
          if (!this.form.endDate) {
            this.form.endDate = moment().add(1, 'month')._d
          }
        })
      }
    },
    'form.stockItem' (val) {
      if (!val) return
      const baseUnitId = this.form.stockItem.baseUnitId
      const type = 1
      this.getRelativeUnitList({baseUnitId, type})
        .then(res => {
          if (!this.isEdit) this.setUnit()
        })
    },

    'form.unitId' () {
      this.reCheckBarcodeList()
      this.getBarcodeOffers()
    },

    'form.discount1' () {
      if ((!this.form.discount1 || this.form.discount1 === 0) && this.form.discount2) {
        this.discountControl = false
      } else {
        this.discountControl = true
      }
    }
  },

  computed: {
    ...mapGetters('PriceQuote', [
      'relativeUnitList'
    ]),

    ...mapGetters('Settings', ['checkPermission']),

    ...mapGetters('Global', ['activeCurrency']),

    stockItemCodeValRule () {
      const supplierId = this.form.supplier ? this.form.supplier.id : null
      const stockItemId = this.form.stockItem ? this.form.stockItem.id : null
      if (this.form.supplierStockItemCode?.length) {
        const url = `PriceQuote/check/stockitemcode?SupplierId=${supplierId}&StockItemId=${stockItemId}`
        return `very_singularity:${url},StockItemCode`
      }
      else return ''
    },

    disabledButton() {
      if (this.form.supplier && this.form.stockItem && this.form.unitId && this.form.unitPrice) return false
      else return true
    },

    directSelectOption () {
      return this.stockItemSelectOptions.length === 1 &&
        this.stockItemSelectOptions[0].matchType === 2
        ? this.stockItemSelectOptions[0]
        : {}
    },

    isLoading () {
      return this.$wait.is(['updatePriceQuote', 'createPriceQuote', 'submitForm', 'getBarcodeSuggestions', 'getPriceQuote'])
    },

    isLoadingCheckBarcode () {
      return this.$wait.is(['checkBarcode'])
    },

    startDateDisabled () {
      return {
        to: moment(this.availableStartDate)._d,
        from: moment(this.form.endDate)._d
      }
    },

    disableBarcodeInput () {
      return this.form.stockItem === null || this.form.unitId === '-1'
    },

    endDateDisabled () {
      return {
        to: moment(this.form.startDate)._d
      }
    },

    isEdit () {
      return this.status === 'edit'
    },

    finalForm () {
      return Object.keys(this.form).reduce((priceQuote, key) => {
        switch (key) {
          case 'supplier':
            return Object.assign({}, priceQuote, {
              supplierId: this.form.supplier.id
            })

          case 'stockItem':
            return Object.assign({}, priceQuote, {
              stockItemId: this.form.stockItem.id
            })

          case 'discount1':
            return Object.assign({}, priceQuote, {
              discount1: this.form.discount1 || 0
            })

          case 'discount2':
            return Object.assign({}, priceQuote, {
              discount2: this.form.discount2 || 0
            })

          case 'unitId':
            return Object.assign({}, priceQuote, {
              unitId: this.form.unitId === '-1' ? null : this.form.unitId
            })

          case 'startDate':
            return Object.assign({}, priceQuote, {
              startDate: this.form.startDate ? moment(this.form.startDate).format('YYYY-MM-DD') : null
            })

          case 'endDate':
            return Object.assign({}, priceQuote, {
              endDate: this.form.endDate ? moment(this.form.endDate).format('YYYY-MM-DD') : null
            })

          default:
            return Object.assign({}, priceQuote, {
              [key]: this.form[key]
            })
        }
      }, {})
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('PriceQuote', [
      'getPriceQuote',
      'createPriceQuote',
      'updatePriceQuote',
      'getAvailableStartDate',
      'getRelativeUnitList',
      'checkBarcode',
      'getBarcodeSuggestions',
      'generateBarcode'
    ]),

    ...mapActions('Stock', [
      'searchStockItem',
      'getStockItem'
    ]),

    ...mapActions('Supplier', [
      'searchSupplierWithPermission',
      'getSupplier'
    ]),

    setUnit (unitId = this.form.stockItem.unitId) {
      this.form.unitId = unitId
    },

    changeBarcodeType (isChecked) {
      isChecked ? this.form.isWeightedBarcode = true : this.form.isWeightedBarcode = false  
      this.disableBarcodeButton = false
    },

    onChangeSupplier () {
      this.currencySymbol = this.form.supplier.currencyDefinitionSymbol
    },

    changedStockItem () {
      if (this.form.stockItem && this.checkPermission('PriceTypeEdit')) {
        const stockItem = this.stockItemSelectOptions.find(item => item.id === this.form.stockItem.id)
        if (stockItem.priceType) this.form.priceType = stockItem.priceType
      }
    },

    reCheckBarcodeList () {
      if (this.isWeightedUnit(this.form.unitId)) return
      let removeList = []

      this.form.barcodes.map(barcode => {
        if (this.isWeighted(barcode)) {
          removeList.push(barcode)
        }
      })
      removeList.forEach(barcodeNumber => {
        const index = this.form.barcodes.findIndex(b => b === barcodeNumber)
        this.removeBarcode(index)
      })
    },

    async getBarcodeOffers () {
      if (!this.form.stockItem) return
      const params = {
        stockItemId: this.form.stockItem.id,
        unitId: this.form.unitId
      }
      this.form.barcodeOffers = (await this.getBarcodeSuggestions(params))
    },

    shortBarcodeLists () {
      this.form.barcodes.sort((a, b) => a - b)
      this.form.barcodeOffers.sort((a, b) => a - b)
    },

    addBarcodeOffer (index) {
      this.form.barcodes.push(this.form.barcodeOffers[index])
      this.shortBarcodeLists()
    },

    addBarcode () {
      if (!this.isLoadingCheckBarcode) {
        if (this.form.barcodes.indexOf(this.barcode) === -1 && !this.veeErrors.has('barcode')) {
          this.form.barcodes.push(this.barcode)
          this.barcode = ''
        } else {
          this.barcode = ''
        }
        this.shortBarcodeLists()
      }
    },

    async createBarcode () {
      const payload = {
        barcodeType: this.form.isWeightedBarcode ? 1 : 2,
        priceQuoteId: this.priceQuoteId,
        unitId: this.form.unitId
      }
      await this.generateBarcode(payload).then(res => {
        if (res) {
          this.form.barcodes.push(res.data.barcodeNumber)
          this.disableBarcodeButton = true
        }
      })
    },

    removeBarcode (index) {
      this.form.barcodes.splice(index, 1)
      this.disableBarcodeButton = false
      this.shortBarcodeLists()
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus (el = 'supplierRef') {
      this.$refs[el].$refs.search.focus()
    },

    resetForm () {
      this.form = {
        supplier: this.form.supplier,
        stockItem: null,
        supplierStockItemCode: null,
        unitId: '-1',
        unitPrice: 0,
        discount1: null,
        discount2: null,
        leadTime: null,
        isValidityDateActive: false,
        startDate: null,
        endDate: null,
        barcodes: []
      }
      this.stockItemSelectOptions = []
      this.availableStartDate = null
      this.$nextTick(() => {
        this.$validator.reset('formPriceQuote')
      })
    },

    getList () {
      this.$emit('getList')
    },

    onSubmitForm: vueWaitLoader(async function () {
      const [ formPriceQuoteValidation, allValidation ] = await Promise.all([
        this.$validator.validateAll('formPriceQuote'),
        this.$validator.validateAll()
      ])
      if (!formPriceQuoteValidation || !allValidation) return
      if (Number(this.barcode) && !this.veeErrors.has(`formPriceQuote.barcode`) && !this.isLoadingCheckBarcode) await this.addBarcode()
      if (this.isEdit) await this.submitEdit()
      else await this.submitNew()
    }, 'submitForm'),

    async submitNew () {
      if (!this.checkPermission('PriceTypeEdit')) this.form.priceType = null
      const message = this.$t('Global.notification_Created', {
        name: this.$t('PriceQuota.popupForm_Name')
      })
      const payload = omit(this.finalForm, ['barcodeOffers'])
      await this.createPriceQuote(payload)
        .then(res => {
          if (!res) return
          this.notifyShow({ message })
          this.getList()
          if (this.saveAndNew) {
            this.resetForm()
          } else {
            this.close()
          }
        })
    },

    async submitEdit () {
      if (!this.checkPermission('PriceTypeEdit')) this.form.priceType = null
      const message = this.$t('Global.notification_Updated', {
        name: this.$t('PriceQuota.popupForm_Name')
      })
      const payload = getObjectValuesByKey(
        this.finalForm,
        ['id', 'unitPrice', 'discount1', 'discount2', 'isValidityDateActive', 'startDate', 'endDate', 'supplierStockItemCode', 'barcodes', 'leadTime', 'priceType', 'isActive']
      )
      if (this.discountControl) {
        await this.updatePriceQuote(payload)
          .then(res => {
            if (!res) return
            if (res) {
              this.notifyShow({ message })
            }
            this.getList()
            this.close()
          })
      }
    },

    getSupplierSelectOptions (search, loading) {
      this.searchSupplierWithPermission(search)
        .then(res => {
          this.supplierSelectOptions = res.data.items
        })
    },

    getStockItemSelectOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 4]})
        .then(res => {
          this.stockItemSelectOptions = res.data.items
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.linear-container {
  display: flex;
  align-items: center;
  label {
    white-space: nowrap;
    margin-right: 10px;
  }
  .linear {
    height: 1px;
    width: 100%;
    background-color: $color-gray;
  }
}
.inline-container {
  .Form-item {
    flex: 1
  }
}
.create-barcode {
  display: flex;
  justify-content: space-between;
}
</style>
