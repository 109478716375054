import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { PriceQuoteCollection } from '../model'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    RelativeUnitList: [],
    PriceQuoteList: new PriceQuoteCollection(),
    FilterData: {
      supplierList: [],
      itemGroupList: [],
      stockItemList: []
    },
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    priceQuoteList: state => state.PriceQuoteList.list,
    isEmpty: state => state.PriceQuoteList.length === 0,
    filterData: state => state.FilterData,
    relativeUnitList: state => state.RelativeUnitList,
    page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getFilterData({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getFilterData',
        methodName: 'getFilterData',
        method: () => {
          return HTTP.get('PriceQuote/filterparams')
        },
        success: result => {
          commit('SET_FILTER_DATA', result.data)
          return result
        }
      })
      return results
    },
    async getRelativeUnitList({ commit, dispatch }, { baseUnitId = null, type = null }) {
      if (baseUnitId === null) {
        return commit('SET_UNIT_LIST', []) // Reset filtered unit list
      }
      let URL = `Unit/filter?BaseUnitId=${baseUnitId}`
      if (type) {
        URL = `Unit/filter?BaseUnitId=${baseUnitId}&type=${type}`
      }
      const results = await requestHandler({
        title: 'getRelativeUnitList',
        methodName: 'getRelativeUnitList',
        method: () => {
          return HTTP.get(URL)
        },
        success: result => {
          commit('SET_UNIT_LIST', result.data.unitList)
          return result
        }
      })
      return results
    },

    async getPriceQuoteList({ commit, state, dispatch }, options = {}) {
      const {
        SupplierIds = state.FilterData.supplierList.map(i => i.id),
        ItemGroupIds = state.FilterData.itemGroupList.map(i => i.id),
        StockItemIds = state.FilterData.stockItemList.map(i => i.id),
        to = 1
      } = options

      const PageNumber = getPaginationNumber({ selectedPage: to, currentPage: state.Page.number })

      const endpoint = 'PriceQuote/all'
      const params = {
        SupplierIds,
        ItemGroupIds,
        StockItemIds,
        PageSize: options.pageSize,
        PageNumber,
        Text: options.Text,
        showPassives: options.showPassives || false,
        showWithBaseCurrency: options.showWithBaseCurrency || false,
        onlyGlobalSuppliers: options.onlyGlobalSuppliers || false
      }

      const loadingName = 'getPriceQuoteList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post(endpoint, params)
        },
        success: result => {
          commit('SET_PRICE_QUOTE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getPriceQuote({ commit, dispatch }, Id) {
      const loadingName = 'getPriceQuote'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('PriceQuote', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async createPriceQuote({ commit, dispatch }, priceQuote) {
      const loadingName = 'createPriceQuote'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('PriceQuote', priceQuote)
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async updatePriceQuote({ commit, dispatch }, priceQuote) {
      const loadingName = 'updatePriceQuote'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('PriceQuote', priceQuote)
        },
        success: result => result
      })
      return results
    },

    async updatePriceQuoteList({ commit, dispatch }, payload) {
      const loadingName = 'updatePriceQuoteList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('PriceQuote/pricetype/list', payload)
        },
        success: result => result
      })
      return results
    },

    async updatePreferrenceState({ commit, dispatch }, payload) {
      const loadingName = 'updatePreferrenceState'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('PriceQuote/preferencestate', payload)
        },
        success: result => result
      })
      return results
    },

    async updatePriceQuoteListActivationStatus({ commit, dispatch }, payload) {
      const loadingName = 'updatePriceQuoteListActivationStatus'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('PriceQuote/activationstate/list', payload)
        },
        success: result => result
      })
      return results
    },

    async getAvailableStartDate({ commit, dispatch }, { SupplierId, StockItemId, UnitId }) {
      const loadingName = 'getAvailableStartDate'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('PriceQuote/availablestartdate', {
            params: {
              SupplierId,
              StockItemId,
              UnitId
            }
          })
        },
        success: result => result
      })
      return results
    },

    async checkBarcode({ commit, dispatch }, params) {
      const loadingName = 'checkBarcode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('PriceQuote/check/barcode', { params })
        },
        success: result => result
      })
      return results
    },

    async getBarcodeSuggestions({commit}, params) {
      const loadingName = 'getBarcodeSuggestions'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('PriceQuote/barcode/suggestion', { params })
        },
        success: result => result
      })
      return results.data.barcodes
    },

    async fetchSupplierWhoHadPriceQuote({commit}, params) {
      const loadingName = 'fetchSupplierWhoHadPriceQuote'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('PriceQuote/supplier/all')
        },
        success: result => result
      })
      return results
    },

    async generateBarcode({ commit }, payload) {
      const loadingName = 'generateBarcode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('PriceQuote/generatebarcode', payload)
        },
        success: result => result
      })
      return results
    },

    async generateBarcodeList({ commit }, params) {
      const loadingName = 'generateBarcodeList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('PriceQuote/generatebarcode/list', params)
        },
        success: result => result
      })
      return results
    },

    async updateImportedPriceQuote({commit}, payload) {
      const loadingName = 'updateImportedPriceQuote'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('PriceQuote/list', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.RelativeUnitList = []
      state.PriceQuoteList = new PriceQuoteCollection()
      state.FilterData = {
        supplierList: [],
        itemGroupList: [],
        stockItemList: []
      }
      state.Page = {
        number: 1,
        size: 20,
        total: 0
      }
    },

    SET_PRICE_QUOTE_LIST(state, data) {
      state.PriceQuoteList.addItems(data.priceQuoteList)
      state.PriceQuoteList.list.forEach(item => {
        item.rowTextClass = item.isActive ? '' : 'TableViewPassiveItemsNoBorder'
      })
      state.Page = Object.assign({}, state.Page, {
        number: data.pageNumber,
        total: data.totalCount,
        size: data.pageSize
      })
    },

    SET_FILTER_DATA(state, data) {
      state.FilterData = data
    },

    SET_UNIT_LIST(state, data) {
      state.RelativeUnitList = data
    }
  }
}
