<template lang="pug">
blured-background
    .imported-price-quotes
      .imported-price-quotes-header
        Icon.imported-price-quotes-header-icon(name="icon-global-refresh-data")
        label.imported-price-quotes-header-title {{ $t('PriceQuota.Exported_Price_Quotes_Header_Title') }}
        label.imported-price-quotes-header-desc {{ $t('PriceQuota.Exported_Price_Quotes_Header_Description') }}
      .imported-price-quotes-table
        TableView(
          :fields="fields"
          :componentName="'PriceQuoteTable'"
          :data="priceQuoteList"
          :tableName="$t('Stocks.tableName')"
          :read-only="true"
          )
      .imported-price-quotes-buttons
        Button.imported-price-quotes-button.yes(
          primary
          size="medium"
          @click="update"
          :isLoading="isLoadingUpdate"
          :disabled="isLoadingUpdate"
          ) {{ $t('PriceQuota.Exported_Price_Quotes_Button_Yes') }}
        Button.imported-price-quotes-button.no(@click="close") {{ $t('PriceQuota.Exported_Price_Quotes_Button_No') }}
</template>

<script>
import bluredBackground from '@/view/global/full-blur-background'
import { mapGetters, mapActions } from 'vuex'
import getFields from '../imported-pq-fields'

export default {
  name: 'ImportedPriceQuotes',

  data () {
    return {
      fields: getFields(this.$t)
    }
  },

  components: {
    bluredBackground
  },

  mounted () {
    if (!this.priceQuoteList || this.priceQuoteList.length === 0) this.$nextTick(() => this.close())
  },

  computed: {
    ...mapGetters('PriceQuote', [
      'priceQuoteList'
    ]),

    isLoadingUpdate () {
      return this.$wait.is(['updateImportedPriceQuote'])
    },

    priceQuoteList () {
      return this.$route.params.importedPriceQuotes
    }
  },

  methods: {
    ...mapActions('PriceQuote', [
      'updateImportedPriceQuote'
    ]),
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    update () {
      const payload = this.priceQuoteList.map(pq => {
        return {
          id: pq.id,
          unitPrice: pq.newUnitPrice,
          discount1: pq.newDiscount1,
          discount2: pq.newDiscount2,
          startDate: pq.newStartDate,
          endDate: pq.newEndDate,
          leadTime: pq.newLeadTime
        }
      })
      this.updateImportedPriceQuote({priceQuoteList: payload})
        .then(() => {
          this.close()
          this.$emit('getList')
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.imported-price-quotes {
  max-height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-table {
    position: relative;
    overflow-y: scroll;
    margin-bottom: 50px;
    max-height: 400px;
    background-color: $color-white;
    box-shadow: -6px 3px 22px 0 rgba(0, 0, 0, 0.08);
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 41px;

    &-icon {
      color: $color-warning;
      margin-bottom: 11px;
      width: 60px;
      height: 60px;
    }

    &-title {
      margin-bottom: 8px;
      font-family: $font-family;
      font-size: $font-size-Hbigger;
      font-weight: $font-weight-bold;
      color: $color-dark;
    }

    &-desc {
      font-family: $font-family;
      font-size: $font-size-big;
      color: $color-light;
    }
  }

  &-buttons {

    display: flex;

    .imported-price-quotes-button {
      width: 281px;
      margin-left: 10px;
      font-size: $font-size-normal;
      transition: all 0.2s ease;

      // button no
      &.no {
        color: $color-light;
        border: 1px solid $color-light;

        &:hover {
          color: darken($color-light, 10%)
        }
      }

      // button yes
      &.yes {

        &:hover {
          color: darken($color-success, 10%)
        }
      }
    }
  }
}
</style>
