var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("blured-background", [
    _c("div", { staticClass: "imported-price-quotes" }, [
      _c(
        "div",
        { staticClass: "imported-price-quotes-header" },
        [
          _c("Icon", {
            staticClass: "imported-price-quotes-header-icon",
            attrs: { name: "icon-global-refresh-data" },
          }),
          _c("label", { staticClass: "imported-price-quotes-header-title" }, [
            _vm._v(
              _vm._s(_vm.$t("PriceQuota.Exported_Price_Quotes_Header_Title"))
            ),
          ]),
          _c("label", { staticClass: "imported-price-quotes-header-desc" }, [
            _vm._v(
              _vm._s(
                _vm.$t("PriceQuota.Exported_Price_Quotes_Header_Description")
              )
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "imported-price-quotes-table" },
        [
          _c("TableView", {
            attrs: {
              fields: _vm.fields,
              componentName: "PriceQuoteTable",
              data: _vm.priceQuoteList,
              tableName: _vm.$t("Stocks.tableName"),
              "read-only": true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "imported-price-quotes-buttons" },
        [
          _c(
            "Button",
            {
              staticClass: "imported-price-quotes-button yes",
              attrs: {
                primary: "",
                size: "medium",
                isLoading: _vm.isLoadingUpdate,
                disabled: _vm.isLoadingUpdate,
              },
              on: { click: _vm.update },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("PriceQuota.Exported_Price_Quotes_Button_Yes"))
              ),
            ]
          ),
          _c(
            "Button",
            {
              staticClass: "imported-price-quotes-button no",
              on: { click: _vm.close },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("PriceQuota.Exported_Price_Quotes_Button_No"))
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }