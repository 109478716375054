import BaseClass from '@/utils/baseclass'

export class PriceQuoteCollection extends BaseClass {
  addItem(item) {
    let newItem = new PriceQuote(item)
    this.list.push(newItem)
  }

  updateItem(newItem) {
    let itemToUpdate = this.findItem(newItem.id)
    if (itemToUpdate) {
      itemToUpdate.id = newItem.id
      itemToUpdate.stockItemId = newItem.stockItemId
      itemToUpdate.stockItemName = newItem.stockItemName
      itemToUpdate.supplierId = newItem.supplierId
      itemToUpdate.supplierName = newItem.supplierName
      itemToUpdate.supplierStockItemCode = newItem.supplierStockItemCode
      itemToUpdate.unitName = newItem.unitName
      itemToUpdate.unitPrice = newItem.unitPrice
      itemToUpdate.baseUnitPrice = newItem.baseUnitPrice
      itemToUpdate.discount1 = newItem.discount1
      itemToUpdate.discount2 = newItem.discount2
      itemToUpdate.isValidityDateActive = newItem.isValidityDateActive
      itemToUpdate.startDate = newItem.startDate
      itemToUpdate.endDate = newItem.endDate
      itemToUpdate.discountedBaseUnitPrice = newItem.discountedBaseUnitPrice
      itemToUpdate.discountedUnitPrice = newItem.discountedUnitPrice
      itemToUpdate.isExpired = newItem.isExpired
      itemToUpdate.storeGroups = newItem.storeGroups
      itemToUpdate.leadTime = newItem.leadTime
      itemToUpdate.priceType = newItem.priceType
      itemToUpdate.isActive = newItem.isActive
      itemToUpdate.rowTextClass = newItem.rowTextClass
      itemToUpdate.currencyDefinitionSymbol = newItem.currencyDefinitionSymbol
      itemToUpdate.isPreferred = newItem.isPreferred
      itemToUpdate.lastPurchaseDate = newItem.lastPurchaseDate
    }
  }
}

class PriceQuote {
  constructor(item) {
    this.id = item.id
    this.stockItemId = item.stockItemId
    this.stockItemName = item.stockItemName
    this.supplierId = item.supplierId
    this.supplierName = item.supplierName
    this.supplierStockItemCode = item.supplierStockItemCode
    this.unitName = item.unitName
    this.unitPrice = item.unitPrice
    this.baseUnitPrice = item.baseUnitPrice
    this.discount1 = item.discount1
    this.discount2 = item.discount2
    this.isValidityDateActive = item.isValidityDateActive
    this.startDate = item.startDate
    this.endDate = item.endDate
    this.discountedBaseUnitPrice = item.discountedBaseUnitPrice
    this.discountedUnitPrice = item.discountedUnitPrice
    this.isExpired = item.isExpired
    this.isSupplierActive = item.isSupplierActive
    this.isSupplierGlobal = item.isSupplierGlobal
    this.storeGroups = item.storeGroups
    this.leadTime = item.leadTime
    this.priceType = item.priceType
    this.isActive = item.isActive
    this.rowTextClass = item.rowTextClass
    this.isPreferred = item.isPreferred
    this.currencyDefinitionSymbol = item.currencyDefinitionSymbol
    this.lastPurchaseDate = item.lastPurchaseDate
  }
}
